import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

const createThemeForColor = (primaryColor: string) =>
  createTheme({
    typography: {
      h3: {
        fontWeight: 500,
        lineHeight: "unset",
        fontSize: "1.8rem",
      },
      h4: {
        fontWeight: 500,
        fontSize: "1.4rem",
      },
    },
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: "#494949",
      },
      error: {
        main: red.A400,
      },
      text: {
        primary: "#2A2A2A",
      },
    },
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            padding: "0.5rem",
            border: "1px solid var(--primary-color)",
            color: "#2A2A2A",
            backgroundColor: "white",
            maxWidth: 360,
            borderRadius: "1rem",
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            borderBottom: "1px solid lightgrey",
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiChip: {
        styleOverrides: {
          filledPrimary: {
            backgroundColor: "var(--primary-color)",
            color: "white",
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          outlined: {
            padding: "0.5rem 2rem 0.5rem 0.5rem",
            borderColor: "var(--primary-color)",
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: "rgba(40, 40, 40, 0.04)",
          },
          colorSecondary: {
            color: primaryColor,
            backgroundColor: "rgba(40, 40, 40, 0.02)",
            "::after": {
              borderBottomColor: primaryColor,
            },
            "&.Mui-focused": {
              backgroundColor: "rgba(40, 40, 40, 0.04)",
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&.Mui-disabled": {
              backgroundColor: "#f6f6f6",
              color: "black",
            },
          },
          input: {
            "&.Mui-disabled": {
              color: "black",
              WebkitTextFillColor: "black",
            },
          },
        },
      },
    },
  });

export default createThemeForColor;
