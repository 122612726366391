import "../styles/globals.css";
import "tailwindcss/tailwind.css";
import Head from "next/head";
import { CacheProvider } from "@emotion/react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "../src/utils/theme";
import createEmotionCache from "../src/utils/create-emotion-cache";
import { default as NextApp } from "next/app";
import { createStore, StoreProvider } from "../src/store";
import { ModalProvider } from "components/shared/modal/modal-context";
import { SWRConfig } from "swr";
import { Cookies, CookiesProvider } from "react-cookie";
import { isServer } from "../src/utils/isServer";
import { fetcher } from "../src/utils/fetcher";
import { AppProgressBar as ProgressBar } from "next-nprogress-bar";
import { ContextProvider } from "components/shared/context-provider";
import { ToolThemeProvider } from "components/shared/tool-theme-provider";

const clientSideEmotionCache = createEmotionCache();

function App({ Component, pageProps, emotionCache, cookies }) {
  const store = createStore();
  return (
    <CacheProvider value={emotionCache ?? clientSideEmotionCache}>
      <StoreProvider value={store}>
        <Head>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Head>
        <SWRConfig value={{ fallback: pageProps.fallback ?? {}, fetcher }}>
          <CookiesProvider
            cookies={isServer ? new Cookies(cookies) : undefined}
          >
            <ContextProvider>
              <ToolThemeProvider>
                <CssBaseline />
                <ProgressBar
                  height="6px"
                  color="var(--primary-color)"
                  options={{ showSpinner: false }}
                  shallowRouting
                />
                <ModalProvider>
                  <Component {...pageProps} />
                </ModalProvider>
              </ToolThemeProvider>
            </ContextProvider>
          </CookiesProvider>
        </SWRConfig>
      </StoreProvider>
    </CacheProvider>
  );
}

App.getInitialProps = async (appContext) => {
  const cookies = appContext.ctx.req?.cookies;
  // On server-side, this runs once and creates new stores
  // On client-side, this always reuses existing stores
  const store = createStore();

  // Make stores available to page's `getInitialProps`
  appContext.ctx.store = store;

  // Call "super" to run page's `getInitialProps`
  const appProps = await NextApp.getInitialProps(appContext);

  // Gather serialization-friendly data from stores
  const initialStoreData = {};

  // Return props
  return {
    ...appProps,
    initialStoreData,
    cookies,
  };
};

export default App;
