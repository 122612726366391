import useSWR from "swr";
import { useMemo } from "react";
import { MessageGroup } from "entities/message-group";
import { useStore } from "./use-store";
import { fetcher } from "../utils/fetcher";

export const useMessageGroups = (): [
  MessageGroup[],
  (mutation?: any, options?: any) => Promise<void>,
  boolean
] => {
  const { loadFromGroups } = useStore((s) => s.data);
  const { data, error, mutate, isValidating } = useSWR(
    `/api/message-groups`,
    fetcher,
    {
      revalidateOnMount: true,
      dedupingInterval: 1000,
    }
  );

  const groups = useMemo<MessageGroup[]>(() => {
    if (!data || error) return [];
    const unorderedGroups = Object.values(data.data) as MessageGroup[];
    const groups = unorderedGroups.sort((a, b) => a.priority - b.priority);
    loadFromGroups(groups);
    return groups as MessageGroup[];
  }, [data, error, loadFromGroups]);

  return [groups, mutate, isValidating];
};
