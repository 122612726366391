import { ReactNode, useContext, useMemo} from "react";
import {Context, context} from "./context";
import {SubMessage} from "entities/sub-message";
import {EvidenceType} from "types/evidence-type";
import {MessageGroup} from "entities/message-group";
import {ParentMessage} from "entities/parent-message";
import {usePathname} from "next/navigation";
import {getToolFromPath} from "components/shared/context-provider/get-tool-from-path";

type ContextProviderProps = {
    children: ReactNode;
    evidenceType?: EvidenceType;
    submessage?: SubMessage;
    group?: MessageGroup;
    message?: ParentMessage;
}
export const ContextProvider = ({children, evidenceType, group, submessage, message}: ContextProviderProps) => {
    const parentContext = useContext(context);

    const pathname = usePathname();
    const tool = getToolFromPath(pathname);

    const newContext = useMemo<Context>(() =>{
        const ctx = {...parentContext};

        if (evidenceType !== undefined) {
            ctx.evidenceType = evidenceType;
        }

        if (submessage !== undefined) {
            ctx.submessage = submessage;
        }

        if (group !== undefined) {
            ctx.group = group;
        }

        if (message !== undefined) {
            ctx.message = message;
        }

        return ctx;
    }, [evidenceType, submessage, group, parentContext]);

    return <context.Provider value={{...newContext, tool}}>{children}</context.Provider>
}
