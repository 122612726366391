import {makeAutoObservable} from "mobx";

const baseEvidence = {
    '1': {author: "Kanas, 2021"},
    "2": {author: "Tkacz 2021"},
    "3": {author: "Tsutsue 2020"}
}

export class EvidenceStore {
    constructor() {
        makeAutoObservable(this);
    }

    evidence = baseEvidence;

    get evidenceList() {
        return Object.keys(this.evidence).map(id=>({
            ...this.evidence[id],
            id
        }));
    }


}
