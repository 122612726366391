import {useContext} from "react";
import {context} from "components/shared/context-provider/context";
import {EvidenceType} from "types/evidence-type";

export const useAppContext = () => {
    const {evidenceType, submessage, ...rest} = useContext(context);

    const isPublishedEvidence = evidenceType === EvidenceType.publishedStudy;

    const submessageId = submessage?.id;

    return {isPublishedEvidence, evidenceType, submessage, submessageId, ...rest};
}
