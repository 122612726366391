import { makeAutoObservable } from "mobx";
import { RootStore } from "../../root";
import { EvidenceGapType } from "types/evidence-gap";
import { ownersByRegion } from "components/iegp/dashboard/single-view/field/gap-owner";
import { Region } from "types/region";

const yearsIntoFuture = 5;

export const createGapCompletionYears = (
  selected: boolean
): Record<number, boolean> => {
  const years: Record<number, boolean> = {};
  const currentYear = new Date().getFullYear();
  for (let i = 0; i <= yearsIntoFuture; i++) {
    years[currentYear + i] = selected;
  }
  return years;
};

export const createGapOwners = (region: Region): Record<string, boolean> => {
  const regionOwners = ownersByRegion[region];
  return Object.keys(regionOwners).reduce(
    (acc, key) => ({ ...acc, [key]: true }),
    {} as Record<string, boolean>
  );
};

export class FilterStore {
  constructor(root: RootStore) {
    this.root = root;
    this.gapCompletionYear = createGapCompletionYears(true);
    this.gapOwner = createGapOwners(Region.GLOBAL);
    makeAutoObservable(this);
  }

  root: RootStore;

  hasTactics: Record<string, boolean> = {
    yes: true,
    no: true,
  };

  gapType: Record<EvidenceGapType, boolean> = Object.keys(
    EvidenceGapType
  ).reduce((acc, key) => {
    acc[EvidenceGapType[key]] = true;
    return acc;
  }, {} as any);

  gapCompletionYear: Record<number, boolean>;

  gapOwner: Record<string, boolean> = {};
}
