import {makeAutoObservable} from "mobx";
import {LinkStore} from "./links";
import {DataStore} from "./data";
import {EvidenceStore} from "./evidence";
import {UiStore} from "./ui";

export class RootStore {
    constructor() {
        makeAutoObservable(this);
    }

    evidence = new EvidenceStore();

    links = new LinkStore();

    data = new DataStore(this);

    ui = new UiStore(this);

}

export type RootStoreSelector<Selection> = (s: RootStore) => Selection;
