import {useContext} from "react";
import {StoreContext} from "../store";
import {RootStore, RootStoreSelector} from "../store/root";

export function useStore(): RootStore;
export function useStore<S>(s: RootStoreSelector<S>): S;

export function useStore<S>(selector?: RootStoreSelector<S>) {
    const store = useContext(StoreContext);
    return selector ? selector(store) : store;
}
