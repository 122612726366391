import { makeAutoObservable } from "mobx";
import { RootStore } from "../root";
import { PlannedEvidence } from "entities/planned-evidence";

export class PublishedEvidenceStore {
  constructor(root: RootStore) {
    this.root = root;
    makeAutoObservable(this);
  }

  root: RootStore;

  filterText = "";

  // TODO: Refactor below to store.ui.publishedEvidence.new

  loading = false;

  addNewExpanded = false;

  downloadLink = "";

  isSponsored = false;

  studyName = "";

  sourceTactic: PlannedEvidence | null = null;

  reset = () => {
    this.downloadLink = "";
    this.addNewExpanded = false;
    this.isSponsored = false;
    this.studyName = "";
    this.sourceTactic = null;
    this.loading = false;
  };

  get canSave() {
    return !!this.studyName.length;
  }

  save = async (submessageId?: number) => {
    this.loading = true;
    const body = {
      label: this.studyName,
      isSponsored: this.isSponsored,
      downloadLink: this.downloadLink,
      sourceTactic: this.sourceTactic?.id,
    };
    const qs = submessageId ? `?submessageId=${submessageId}` : "";
    const resp = await fetch(`/api/published-evidence${qs}`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(body),
    });

    if (resp.status !== 200) {
      this.loading = false;
      return;
    }

    this.reset();

    const data = await resp.json();
    this.root.data.publishedEvidenceData[data.id] = data;
    return data;
  };
}
