import {isServer} from "../utils/isServer";
import {RootStore} from "./root";
import { configure } from "mobx"

configure({
    enforceActions: "never",
})

let clientSideStores;

export const createStore = () : RootStore => {
    if (isServer) {
        return new RootStore();
    }

    clientSideStores ??= new RootStore();

    return clientSideStores;
}
