import {makeAutoObservable} from "mobx";
import {RootStore} from "../../root";
import {AddNewStore} from "./add-new";

export class PlannedEvidenceStore {
    constructor(root: RootStore) {
        this.root = root;
        this.addNew = new AddNewStore(root);
        makeAutoObservable(this);
    }

    root: RootStore;

    addNew: AddNewStore;

    filterText = "";
}
