export enum EvidenceGapType {
  EFFICACY = "EFFICACY",
  SAFETY = "SAFETY",
  DOSING = "DOSING",
  BIOMARKERS_AND_TESTING = "BIOMARKERS AND TESTING",
  ECONOMIC_HUMANISTIC_VALUE = "ECONOMIC AND HUMANISTIC VALUE",
  OTHER = "OTHER",
}

export enum EvidenceGapStatus {
  NOT_STARTED = "NOT STARTED",
  IN_PROGRESS = "IN PROGRESS",
  COMPLETED = "COMPLETED",
}

export enum EvidenceGapFieldBulletType {
  DEFAULT = "DEFAULT",
  WHOLE_NUMBER = "WHOLE_NUMBER",
  DECIMAL = "DECIMAL",
}

export enum EvidenceGapPriority {
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
}
