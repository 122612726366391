import {createContext, PropsWithChildren} from "react";
import {createStore} from "./create-store";

const defaultStore = createStore();

export const StoreContext = createContext(defaultStore);

type StoreProviderProps = {
    value: typeof defaultStore;
}
export const StoreProvider = ({value, children}: PropsWithChildren<StoreProviderProps>) => {
    return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>;
}
