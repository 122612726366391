import { makeAutoObservable } from "mobx";
import { RootStore } from "../../root";
import { SingleViewStore } from "./single-view";
import { FilterStore } from "./filter";

export class EvidenceGapsStore {
  constructor(root: RootStore) {
    this.root = root;
    this.singleView = new SingleViewStore(root);
    this.filter = new FilterStore(root);
    makeAutoObservable(this);
  }

  root: RootStore;

  filter: FilterStore;

  singleView: SingleViewStore;
}
