import {makeAutoObservable} from "mobx";

export enum LinkType {
    EVIDENCE,
    STUDY
}

const baseEvidenceLinks = {"UN1A": "1"}

const baseStudyLinks = {"UN1A": null}

export class LinkStore {
    constructor() {
        makeAutoObservable(this);
    }

    evidenceLinks = baseEvidenceLinks;

    studyLinks = baseStudyLinks;

    addLink = (type: LinkType, messageId: string, linkId: string) => {
        switch (type) {
            case LinkType.EVIDENCE:
                this.evidenceLinks = {...this.evidenceLinks, [messageId]: linkId}
                break;
            case LinkType.STUDY:
                this.studyLinks = {...this.studyLinks, [messageId]: linkId}
        }
    }

    removeLink = (type: LinkType, messageId: string) => {
        switch (type) {
            case LinkType.EVIDENCE:
                delete this.evidenceLinks[messageId];
                this.evidenceLinks = {...this.evidenceLinks}
                break;
            case LinkType.STUDY:
                delete this.studyLinks[messageId];
                this.studyLinks = {...this.studyLinks}
        }
    }

}
