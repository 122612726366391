import {
    createContext,
    Dispatch,
    PropsWithChildren,
    FunctionComponent,
    useContext,
    useReducer
} from "react";
import {Modal} from "components/shared/modal/index";
import {ContextProvider} from "components/shared/context-provider";
import {useAppContext} from "../../../hooks/use-app-context";

type ModalState = [FunctionComponent, Record<string, any> | undefined][];

type ModalContextValue = { modals: ModalState, dispatchModal: Dispatch<ModalAction> };

const ModalContext = createContext<ModalContextValue>({
    modals: [], dispatchModal: () => {
    }
});

type ModalAction = {
    type: "ADD" | "CLEAR" | "POP" | "REPLACE";
    modal?: FunctionComponent;
    props?: Record<string, any> & {onExit?: () => void};
}

const reducer = (modals: ModalState, action: ModalAction) => {
    const clone = [...modals];
    switch (action.type) {
        case 'ADD':
            clone.push([action.modal, action.props]);
            break;
        case 'CLEAR':
            return [];
        case 'POP':
            const [_, props] = clone.pop();
            props?.onExit?.();
            break;
        case 'REPLACE':
            const last = clone.pop();
            last[0] = action.modal ?? last[0];
            last[1] = {...last[1], ...action.props};
            clone.push(last);
            break;
    }
    return [...clone];
}

export const ModalProvider = ({children}: PropsWithChildren<{}>) => {
    const {submessage, ...rest} = useAppContext();
    const [state, dispatch] = useReducer(reducer, []);
    return <ContextProvider>
        <ModalContext.Provider value={{modals: state, dispatchModal: dispatch}}>
        <Modal/>
        {children}
    </ModalContext.Provider>
    </ContextProvider>
}

export const useModalContext = () => useContext(ModalContext);
