import {createContext} from "react";
import {SubMessage} from "entities/sub-message";
import {EvidenceType} from "types/evidence-type";
import {MessageGroup} from "entities/message-group";
import {ParentMessage} from "entities/parent-message";
import {Tool} from "types/tool";

export type Context = {
    evidenceType?: EvidenceType;
    submessage: SubMessage;
    group?: MessageGroup;
    message?: ParentMessage;
    tool: Tool | null;
}
export const context = createContext<Context>({} as Context);
