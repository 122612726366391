import { CSSProperties, ReactNode, useMemo } from "react";
import { useAppContext } from "../../hooks/use-app-context";
import { alpha, darken, lighten, ThemeProvider } from "@mui/material";
import { Tool } from "types/tool";
import createThemeForColor from "../../utils/theme";

export const toolToColor = {
  [Tool.GVEF]: "#691A6B",
  [Tool.IEGP]: "#009D94",
};

export const ToolThemeProvider = ({
  children,
  overrideColor,
}: {
  children: ReactNode;
  overrideColor?: string;
}) => {
  const { tool } = useAppContext();

  const [colorStyle, theme] = useMemo(() => {
    const color = overrideColor ?? toolToColor[tool ?? Tool.GVEF];
    return [
      `
        --primary-color-50: ${lighten(color, 0.9)};
        --primary-color: ${color};
        --primary-color-700: ${darken(color, 0.2)};
        --primary-color-light: ${lighten(color, 0.7)};
        --primary-color-clear: ${alpha(color, 0.7)};
      `,
      createThemeForColor(color),
    ];
  }, [tool, overrideColor]);

  return (
    <>
      <style>
        {`
            * {
                ${colorStyle}
            }
            `}
      </style>
      <ThemeProvider theme={theme ?? createThemeForColor("")}>
        {children}
      </ThemeProvider>
    </>
  );
};
