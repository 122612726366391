import { makeAutoObservable } from "mobx";
import { RootStore } from "../root";
import { PlannedEvidence } from "entities/planned-evidence";
import { EvidenceGap } from "entities/evidence-gap";
import { EvidenceGapPriority } from "types/evidence-gap";
import { EvidenceGapField } from "entities/evidence-gap-field";
import { EvidenceGapFieldContent } from "entities/evidence-gap-field-content";
import { EvidenceGapType } from "api/utils";

export class EvidenceGapsStore {
  constructor(root: RootStore) {
    this.root = root;
    makeAutoObservable(this);
  }

  root: RootStore;

  data: Record<number, EvidenceGap> = {};

  private _fields: Record<number, EvidenceGapField> = {};

  loadFromApi = (data: Record<string, any>) => {
    const { evidenceGaps } = data;
    const newData = {};
    for (const gap of evidenceGaps) {
      newData[gap.id] = gap;
    }
    this.data = newData;
  };

  loadFieldsFromApi = (data: Record<string, any>) => {
    const { evidenceGapFields } = data;
    const newFields = {};
    for (const field of evidenceGapFields) {
      newFields[field.id] = field;
    }
    this._fields = newFields;
  };

  gapsByType(type: EvidenceGapType): EvidenceGap[] {
    return Object.values(this.data)
      .filter((gap) => gap.type === type)
      .sort((a, b) => a.priority - b.priority);
  }

  gapsByPriority(priority: EvidenceGapPriority): EvidenceGap[] {
    return Object.values(this.data)
      .filter((gap) => gap.gapPriority === priority)
      .sort((a, b) => a.id - b.id)
      .sort((a, b) => a.type.localeCompare(b.type));
  }

  get gaps(): EvidenceGap[] {
    return Object.values(this.data);
  }

  get fields(): EvidenceGapField[] {
    return Object.values(this._fields).sort((a, b) => a.priority - b.priority);
  }

  get impactAreasField(): EvidenceGapField {
    return Object.values(this._fields).find(
      (f) => f.label === "Impact Area(s)"
    );
  }

  moveGap = async (id: number, direction: "up" | "down") => {
    const gap = this.data[id];
    const gaps = this.gapsByType(gap.type);
    const index = gaps.findIndex((g) => g.id === id);
    const targetIndex = direction === "up" ? index - 1 : index + 1;
    if (targetIndex < 0 || targetIndex >= gaps.length) {
      return;
    }
    const targetGap = gaps[targetIndex];
    const targetPriority = targetGap.priority;
    const targetCode = targetGap.code;
    const currentPriority = gap.priority;
    const currentCode = gap.code;

    await Promise.all([
      this.save(id, { priority: targetPriority, code: targetCode }),
      this.save(targetGap.id, { priority: currentPriority, code: currentCode }),
    ]);
  };

  getById = async (id: number, forceRefresh?: boolean) => {
    if (!forceRefresh && this.data[id]) {
      return this.data[id];
    }
    const resp = await fetch(`/api/evidence-gaps/${id}`, {
      headers: { "content-type": "application/json" },
    });
    if (resp.status === 200) {
      this.data[id] = await resp.json();
    }
    return this.data[id];
  };

  create = async (gap: Partial<EvidenceGap>): Promise<EvidenceGap | null> => {
    const resp = await fetch(`/api/evidence-gaps`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(gap),
    });
    if (resp.status === 200) {
      const savedGap = await resp.json();
      this.data = { ...this.data, [savedGap.id]: savedGap };
      return savedGap;
    }

    return null;
  };

  save = async (
    id: number | null,
    gap: Partial<EvidenceGap>
  ): Promise<EvidenceGap | null> => {
    if (id === null) {
      return this.create(gap);
    }

    this.data[id] = { ...this.data[id], ...(gap as any) };

    const resp = await fetch(`/api/evidence-gaps/${id}`, {
      method: "PUT",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(gap),
    });

    if (resp.status === 200) {
      const savedGap = await resp.json();
      this.data = { ...this.data, [savedGap.id]: savedGap };
      return savedGap;
    }

    return null;
  };

  saveField = async (
    id: number,
    fieldId: number,
    fieldContent: Partial<EvidenceGapFieldContent>
  ): Promise<EvidenceGapFieldContent | null> => {
    const resp = await fetch(`/api/evidence-gaps/${id}/fields/${fieldId}`, {
      method: "PUT",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(fieldContent),
    });

    if (resp.status === 200) {
      this.getById(id, true);
      return await resp.json();
    }

    return null;
  };

  delete = async (id: number): Promise<boolean> => {
    const backup = { ...this.data[id] };
    delete this.data[id];

    const resp = await fetch(`/api/evidence-gaps/${id}`, {
      method: "DELETE",
      headers: { "content-type": "application/json" },
    });

    if (resp.status !== 202) {
      this.data = { ...this.data, [id]: backup as any };
      return false;
    }

    return true;
  };
}
