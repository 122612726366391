import { makeAutoObservable } from "mobx";
import { RootStore } from "../root";
import { PlannedEvidence } from "entities/planned-evidence";

export class PlannedEvidenceStore {
  constructor(root: RootStore) {
    makeAutoObservable(this);
  }

  data: Record<number, PlannedEvidence> = {};

  get allEvidence(): PlannedEvidence[] {
    return Object.values(this.data).sort((a, b) =>
      a.abbrvName.localeCompare(b.abbrvName)
    );
  }

  getById = async (id: number) => {
    if (this.data[id]) {
      return this.data[id];
    }
    const resp = await fetch(`/api/planned-evidence/${id}`, {
      headers: { "content-type": "application/json" },
    });
    if (resp.status === 200) {
      this.data[id] = await resp.json();
    }
    return this.data[id];
  };

  save = async (id: number, evidence: Partial<PlannedEvidence>) => {
    this.data[id] = {
      ...this.data[id],
      ...evidence,
      messages: this.data[id].messages,
      subMessages: this.data[id].subMessages,
      summaryJson: this.data[id].summaryJson,
    } as any;
    const resp = await fetch(`/api/planned-evidence/${id}`, {
      method: "PUT",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(evidence),
    });
    if (resp.status === 200) {
      this.data[id] = await resp.json();
    }
    return resp;
  };

  delete = async (id: number) => {
    const backup = { ...this.data[id] };
    delete this.data[id];
    const resp = await fetch(`/api/planned-evidence/${id}`, {
      method: "DELETE",
      headers: { "content-type": "application/json" },
    });
    if (resp.status !== 202) {
      this.data[id] = backup as any;
    }
  };
}
