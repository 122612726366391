import {makeAutoObservable} from "mobx";
import {RootStore} from "../root";
import {PublishedEvidence} from "entities/published-evidence";

export class PublishedEvidenceStore {
    constructor(root: RootStore) {
        this.root = root;
        makeAutoObservable(this);
    }

    root: RootStore;

    get data() {
        return this.root.data.publishedEvidenceData;
    }

    get allEvidence(): PublishedEvidence[] {
        return Object.values(this.data).sort((a, b)=>a.label.localeCompare(b.label));
    }

    getById = async (id: number) => {
        if (this.data[id]) {
            return this.data[id];
        }
        const resp = await fetch(`/api/published-evidence/${id}`, {
            headers: {'content-type': 'application/json'},
        });
        if (resp.status === 200) {
            this.data[id] = await resp.json();
        }
        return this.data[id];
    }

    delete = async (id: number) => {
        const backup = {...this.data[id]};
        delete this.data[id];
        const resp = await fetch(`/api/published-evidence/${id}`, {
            method: 'DELETE',
            headers: {'content-type': 'application/json'}
        });
        if (resp.status !== 202) {
            this.data[id] = backup;
        }
    }

    save = async (id: number, evidence: Partial<PublishedEvidence>) => {
        this.data[id] = {...this.data[id], ...evidence};
        const resp = await fetch(`/api/published-evidence/${id}`, {
            method: 'PUT',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify(evidence)
        });
        if (resp.status === 200) {
            this.root.data.publishedEvidenceData[id] = {...this.root.data.publishedEvidenceData[id], ...await resp.json()};
        }
        return resp;
    }

}
