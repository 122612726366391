import styles from './index.module.css';
import {useCallback, useEffect, useMemo} from "react";
import {Modal as MuiModal} from "@mui/material";
import {HighlightOffSharp} from "@mui/icons-material";
import {useModalContext} from "components/shared/modal/modal-context";
import {useRouter} from "next/router";
import {GroupTheme} from "components/shared/group-theme";
import {ModalContainer} from "components/shared/modal/container";

export const Modal = () => {
    const {modals, dispatchModal} = useModalContext();
    const {events} = useRouter();

    useEffect(() => {
        const handleRouteChange = () => {
            dispatchModal({type: "CLEAR"});
        };
        events.on('routeChangeStart', handleRouteChange);
        return () => {
            events.off('routeChangeStart', handleRouteChange);
        };
    }, [dispatchModal, events]);

    const onClose = useCallback(() => {
        dispatchModal({type: "POP"});
    }, [dispatchModal]);

    const [ModalComponent, props] = useMemo(() => modals[modals.length - 1] ?? [], [modals]);
    return <MuiModal open={!!modals.length} className={styles.root} tabIndex={1} onClose={onClose}>
        <GroupTheme submessageId={props?.submessageId}>
            <ModalContainer onClose={onClose}>
                {ModalComponent && <ModalComponent {...props}/>}
            </ModalContainer>
        </GroupTheme>
    </MuiModal>
    ;
};
