import { makeAutoObservable, reaction } from "mobx";
import { RootStore } from "../../root";
import {
  EvidenceGapPriority,
  EvidenceGapStatus,
  EvidenceGapType,
} from "types/evidence-gap";
import { memoize, throttle } from "lodash";
import { EvidenceGapFieldContent } from "entities/evidence-gap-field-content";

export class SingleViewStore {
  constructor(root: RootStore) {
    this.root = root;
    makeAutoObservable(this);

    reaction(
      () => [this.priority, this.type, this.label],
      () => {
        if (this.canSave) {
          this.throttledSaveChanges();
        }
      }
    );
  }

  root: RootStore;

  loading = false;

  loadingId: string = "";

  savedAt: null | Date = null;

  error: null | string = null;

  id: null | number = null;

  priority: null | EvidenceGapPriority = null;

  type: null | EvidenceGapType = null;

  status: null | EvidenceGapStatus = null;

  label = "";

  code = "";

  focusedFieldId: null | number = null;

  fields: Record<number, EvidenceGapFieldContent> = {};

  blockSave = false;

  isInitializing = false;

  reset = () => {
    this.loading = false;
    this.loadingId = "";
    this.isInitializing = false;
    this.error = null;
    this.blockSave = false;
    this.priority = null;
    this.type = null;
    this.label = "";
    this.code = "";
    this.status = null;
    this.id = null;
    this.savedAt = null;
    this.focusedFieldId = null;
    this.fields = {};
  };

  loadFromId = async (id: number) => {
    this.setLoading(true, `gap/${id}`);
    this.isInitializing = true;
    this.blockSave = true;
    this.id = id;
    this.savedAt = null;
    const gap = await this.root.data.evidenceGaps.getById(id);
    this.priority = gap.gapPriority;
    this.type = gap.type;
    this.code = gap.code;
    this.label = gap.label;
    this.status = gap.status;
    if (!Object.keys(this.fields).length) {
      this.fields = (gap.fields as any).reduce(
        (acc, field) => ({ ...acc, [field.field.id]: field }),
        {}
      );
    }
    this.setLoading(false, `gap/${id}`);
    this.blockSave = false;
    this.isInitializing = false;
  };

  setLoading = (loading: boolean, id: string, setSavedAt: boolean = false) => {
    if (loading) {
      this.loadingId = id;
      this.loading = true;
    } else if (this.loadingId === id) {
      this.loading = false;
      if (setSavedAt) {
        this.savedAt = new Date();
      }
    }
  };

  updateField = (fieldId: number, field: Partial<EvidenceGapFieldContent>) => {
    this.fields = {
      ...this.fields,
      [fieldId]: {
        ...this.fields[fieldId],
        ...field,
      },
    };
    if (this.id !== null) {
      this.throttleSaveField(fieldId)();
    }
  };

  saveAllFields = () => {
    for (const fieldId in this.fields) {
      this.throttleSaveField(Number(fieldId))();
    }
  };

  get canSave() {
    return !!this.type && !!this.label.length && !this.blockSave;
  }

  throttledSaveChanges = throttle(() => {
    this.saveChanges();
  }, 1000);

  saveChanges = async () => {
    this.setLoading(true, `gap/${this.id}`);
    const gap = await this.root.data.evidenceGaps.save(this.id, {
      gapPriority: this.priority!,
      type: this.type!,
      label: this.label,
      status: this.status!,
      fields: Object.values(this.fields) as any,
    });
    if (!gap) {
      this.setLoading(false, `gap/${this.id}`);
      this.error = "Failed to save changes";
      return;
    }
    // If the gap was just created, we need to update the id
    if (this.id === null) {
      this.id = gap.id;
      this.saveAllFields();
    }
    await this.loadFromId(gap.id);
    this.setLoading(false, `gap/${this.id}`, true);
  };

  delete = async () => {
    this.setLoading(true, `deleteGap/${this.id}`);
    const deleted = await this.root.data.evidenceGaps
      .delete(this.id)
      .then(() => {
        this.setLoading(false, `deleteGap/${this.id}`, true);
        return true;
      })
      .catch((e) => {
        this.setLoading(false, `deleteGap/${this.id}`);
        this.error = "Failed to delete gap";
        return false;
      });

    return deleted;
  };

  throttleSaveField = memoize((fieldId: number) =>
    throttle(async () => {
      await this.saveField(fieldId);
    }, 1000)
  );

  saveField = async (fieldId: number) => {
    const field = this.fields[fieldId];

    if (!field) {
      return;
    }
    this.setLoading(true, `field/${this.id}/${fieldId}`);

    const updatedField = await this.root.data.evidenceGaps.saveField(
      this.id!,
      fieldId,
      field
    );
    if (!updatedField) {
      this.setLoading(false, `field/${this.id}/${fieldId}`);
      this.error = "Failed to save field";
      return;
    }
    this.setLoading(false, `field/${this.id}/${fieldId}`, true);
  };
}
