import {makeAutoObservable} from "mobx";

export class LinksStore {
    constructor() {
        makeAutoObservable(this);
    }

    hasNoPlannedStudy = false;

    hasGap = false;
}
