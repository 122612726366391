import styles from "components/shared/modal/index.module.css";
import {HighlightOffSharp} from "@mui/icons-material";

type ModalContainerProps = {
    className?: string;
    children: React.ReactNode;
    onClose: () => void;
}
export const ModalContainer = ({children, onClose, className}: ModalContainerProps) => {
    return <div className={`${styles.modal} ${className ?? ""}`} onClick={e => e.stopPropagation()}>
        <HighlightOffSharp className={styles.close} fontSize={'large'} onClick={onClose}
                           data-step={"modal-close"}/>
        <div className={styles.scrollContainer}>
            {children}
        </div>
    </div>;
}
