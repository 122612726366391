import { makeAutoObservable } from "mobx";
import { RootStore } from "../../root";
import { PlannedEvidence } from "entities/planned-evidence";
import { Tool } from "types/tool";

export class AddNewStore {
  constructor(root: RootStore) {
    this.root = root;
    makeAutoObservable(this);
  }

  root: RootStore;

  expanded = false;

  loading = false;

  studyName = "";

  abbrv = "";

  reset = () => {
    this.expanded = false;
    this.studyName = "";
    this.abbrv = "";
    this.loading = false;
  };

  get canSave() {
    return !!this.studyName.length && !!this.abbrv.length;
  }

  save = async (tool?: Tool) => {
    this.loading = true;
    const body: Partial<PlannedEvidence> = {
      label: this.studyName,
      abbrvName: this.abbrv,
    };
    const resp = await fetch(`/api/planned-evidence?tool=${tool}`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(body),
    });

    if (resp.status !== 200) {
      this.loading = false;
      return;
    }

    this.reset();

    const data = await resp.json();
    this.root.data.plannedEvidence.data = {
      ...this.root.data.plannedEvidence.data,
      [data.id]: data,
    };
    return data;
  };
}
