import { makeAutoObservable } from "mobx";
import { EvidenceStrength } from "types/evidence-strength";
import { EvidenceType } from "types/evidence-type";
import { Support } from "types/support";
import { LinksStore } from "./links";
import { PublishedEvidenceStore } from "./published-evidence";
import { PlannedEvidenceStore } from "./planned-evidence";
import { RootStore } from "../root";
import { WalkthroughKeys } from "types/walkthrough-keys";
import { EvidenceGapsStore } from "./evidence-gaps";

export type SupportFilter = Partial<Record<Support, boolean>>;

export class UiStore {
  constructor(root: RootStore) {
    this.publishedEvidence = new PublishedEvidenceStore(root);
    this.plannedEvidence = new PlannedEvidenceStore(root);
    this.evidenceGaps = new EvidenceGapsStore(root);
    makeAutoObservable(this);
  }

  links = new LinksStore();

  evidenceGaps: EvidenceGapsStore;

  plannedEvidence: PlannedEvidenceStore;

  publishedEvidence: PublishedEvidenceStore;

  currentWalkthroughKey: WalkthroughKeys | null = null;

  downloadPageTab: "gvef" | "tactics" = "gvef";

  loginFieldValue = "";

  passwordFieldValue = "";

  supportFilter: SupportFilter = {
    [Support.full]: true,
    [Support.partial]: true,
    [Support.none]: true,
    [Support.sponsored]: true,
  };

  toggleSupportFilter = (support: Support) => () => {
    const newSupports = {
      ...this.supportFilter,
      [support]: !this.supportFilter[support],
    };

    this.supportFilter = newSupports;
  };

  get filterQueryString() {
    let str = "";

    const supports = Object.keys(this.supportFilter).filter(
      (k) => this.supportFilter[k]
    );
    if (supports.length) {
      str += `supports=${supports.join(",")}&`;
    }

    return str;
  }

  // TODO: Move to store.ui.links -->

  plannedEvidenceStrengths: Record<
    string,
    Record<string, EvidenceStrength | null>
  > = {};

  publishedEvidenceStrengths: Record<
    string,
    Record<string, EvidenceStrength | null>
  > = {};

  resetSubmessage = (submessageId: number) => () => {
    this.plannedEvidenceStrengths[submessageId] = {};
    this.publishedEvidenceStrengths[submessageId] = {};
    this.linkVisuals[submessageId] = {};
    delete this.labels[submessageId];
  };

  deleteSubmessageTacticLink = (submessageId: number, tacticId: number) => {
    if (!this.plannedEvidenceStrengths[submessageId]) return;
    this.plannedEvidenceStrengths[submessageId] = {
      ...this.plannedEvidenceStrengths[submessageId],
      [tacticId]: null,
    };
  };

  setEvidenceStrength = (evidenceType: EvidenceType, submessageId: number) => (
    evidenceId: number,
    strength: EvidenceStrength | null
  ) => {
    if (evidenceType === EvidenceType.plannedStudy) {
      this.plannedEvidenceStrengths[submessageId] ??= {};
      const strengths = this.plannedEvidenceStrengths[submessageId];
      this.plannedEvidenceStrengths[submessageId] = {
        ...strengths,
        [evidenceId]: strength,
      };
    } else {
      this.publishedEvidenceStrengths[submessageId] ??= {};
      const strengths = this.publishedEvidenceStrengths[submessageId];
      this.publishedEvidenceStrengths[submessageId] = {
        ...strengths,
        [evidenceId]: strength,
      };
    }
  };

  getEvidenceStrengths = (evidenceType: EvidenceType, submessageId: number) => {
    if (evidenceType === EvidenceType.plannedStudy) {
      return this.plannedEvidenceStrengths[submessageId] ?? {};
    } else {
      return this.publishedEvidenceStrengths[submessageId] ?? {};
    }
  };

  linkVisuals: Record<string, Record<string, number>> = {};

  setVisual = (submessageId: number) => (
    evidenceId: number,
    visualId: number
  ) => {
    this.linkVisuals[submessageId] ??= {};
    this.linkVisuals[submessageId][evidenceId] = visualId;
  };

  getVisual = (submessageId: number, evidenceId: number) => {
    return this.linkVisuals[submessageId]?.[evidenceId];
  };

  getEditorVisuals = (submessageId: number) => {
    return this.linkVisuals[submessageId] ?? {};
  };

  labels: Record<number, string> = {};

  setLabel = (submessageId: number) => (label: string) => {
    this.labels[submessageId] = label;
  };

  getLabel = (submessageId: number): string | null => {
    return this.labels[submessageId] ?? null;
  };
}
