import { makeAutoObservable } from "mobx";
import { MessageGroup } from "entities/message-group";
import { ParentMessage } from "entities/parent-message";
import { SubMessage } from "entities/sub-message";
import { PublishedEvidence } from "entities/published-evidence";
import { RootStore } from "../root";
import { PlannedEvidenceStore } from "./planned-evidence";
import { PublishedEvidenceStore } from "./published-evidence";
import { EvidenceGapsStore } from "./evidence-gaps";

export class DataStore {
  constructor(root: RootStore) {
    this.evidenceGaps = new EvidenceGapsStore(root);
    this.plannedEvidence = new PlannedEvidenceStore(root);
    this.publishedEvidence = new PublishedEvidenceStore(root);
    makeAutoObservable(this);
  }

  evidenceGaps: EvidenceGapsStore;

  plannedEvidence: PlannedEvidenceStore;

  publishedEvidence: PublishedEvidenceStore;

  // Messages
  deleteSubmessage = (submessage: SubMessage) => {
    const id = submessage.id.toString();
    delete this.submessages[id];
    this.submessages = { ...this.submessages };
  };
  loadMessage = (message: ParentMessage) => {
    const submessages = ((message.subMessages as any) as SubMessage[]) ?? [];
    submessages.forEach(this.loadSubmessage);
  };

  loadSubmessage = (submessage: SubMessage) => {
    const id = submessage.id.toString();
    this.submessages = { ...this.submessages, [id]: submessage };
  };

  restoreSubmessageFromSnapshot = async (
    submessageId: number,
    snapshotId: number
  ) => {
    const resp = await fetch(
      `/api/sub-messages/${submessageId}/snapshots/${snapshotId}`,
      {
        method: "PUT",
        headers: { "content-type": "application/json" },
      }
    );
    if (resp.status !== 200) return;
    const submessage = await resp.json();
    this.loadSubmessage(submessage);
    return submessage;
  };

  refreshSubmessage = async (id: number) => {
    const resp = await fetch(`/api/sub-messages/${id}`, {
      method: "GET",
    });
    if (resp.status !== 200) return;
    this.loadSubmessage(await resp.json());
  };

  saveSubmessage = async (id: number, submessage: Partial<SubMessage>) => {
    const resp = await fetch(`/api/sub-messages/${id}`, {
      method: "PUT",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(submessage),
    });
    if (resp.status !== 200) return;
    this.loadSubmessage(await resp.json());
    return submessage;
  };

  saveMessage = async (id: number, parentMessage: Partial<ParentMessage>) => {
    const resp = await fetch(`/api/parent-messages/${id}`, {
      method: "PUT",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(parentMessage),
    });
    if (resp.status !== 200) return;
    return parentMessage;
  };

  loadFromGroups = (groups: MessageGroup[]) => {
    groups.forEach((g) => {
      const messages = (g.parentMessages as any) as ParentMessage[];
      messages.forEach(this.loadMessage);
    });
  };

  submessages: Record<string, SubMessage> = {};

  // Evidence

  publishedEvidenceData: Record<string, PublishedEvidence> = {};

  getPublishedEvidenceById = (id: number): PublishedEvidence | null => {
    return this.publishedEvidenceData[id] ?? null;
  };

  refreshPublishedEvidenceById = async (
    id: number
  ): Promise<PublishedEvidence | null> => {
    const resp = await fetch(`/api/published-evidence/${id}`, {
      method: "GET",
    });
    if (resp.status === 200) {
      this.publishedEvidenceData[id] = {
        ...this.publishedEvidenceData[id],
        ...(await resp.json()),
      };
    }
    return this.publishedEvidenceData[id] ?? null;
  };

  savePublishedEvidence = async (
    id: number,
    evidence: Partial<PublishedEvidence>,
    cached?: boolean
  ) => {
    this.publishedEvidenceData[id] = {
      ...this.publishedEvidenceData[id],
      ...evidence,
    };
    if (cached) return;
    const resp = await fetch(`/api/published-evidence/${id}`, {
      method: "PUT",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(evidence),
    });
    if (resp.status === 200) {
      this.publishedEvidenceData[id] = {
        ...this.publishedEvidenceData[id],
        ...(await resp.json()),
      };
    }
  };
}
