import {MessageGroup} from "entities/message-group";
import {CSSProperties, ReactNode, useMemo} from "react";
import {lighten} from "@mui/material";
import {SubMessage} from "entities/sub-message";
import {useMessageGroups} from "../../../hooks/use-message-groups";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../hooks/use-store";

type GroupThemeProps = {
    submessageId?: number;
    children: ReactNode;
}
export const GroupTheme = observer(({submessageId, children}: GroupThemeProps) => {
    const [groups] = useMessageGroups();
    const submessage = useStore(s => s.data.submessages[submessageId]);
    const group = groups?.find(g => submessage?.code.startsWith(g.code));

    const colorStyle = useMemo(() => {
        if (!group?.themeColor) return {};
        return {
            "--primary-color": group.themeColor,
            "--primary-color-light": lighten(group.themeColor, 0.9),
        } as CSSProperties;
    }, [group]);

    return <div style={colorStyle}>{children}</div>
});
