import { useEffect, useState } from "react";
import { EvidenceGapField } from "entities/evidence-gap-field";
import { useStore } from "../../../../../hooks/use-store";
import { observer } from "mobx-react-lite";
import { CheckBoxOutlineBlank, CheckBoxOutlined } from "@mui/icons-material";
import { Divider } from "components/core/divider";
import { EvidenceGapFieldContent } from "entities/evidence-gap-field-content";
import { Region } from "types/region";

type GapOwnerProps = {
  field: EvidenceGapField;
};

export const ownersByRegion: Partial<Record<
  Region,
  Record<string, boolean>
>> = {
  [Region.GLOBAL]: {
    ["CORE"]: false,
    ["Medical Affairs"]: false,
    ["Clinical Strategy"]: false,
    ["Translational"]: false,
  },
  [Region.UNITED_STATES]: {
    ["CORE"]: false,
    ["Medical Affairs"]: false,
    ["Clinical Strategy"]: false,
  },
  [Region.JAPAN]: {
    ["CORE"]: false,
    ["Medical Affairs"]: false,
    ["Clinical Strategy"]: false,
  },
};

const defaultContent = {
  jsonContent: ownersByRegion,
};

export const GapOwner = observer(({ field }: GapOwnerProps) => {
  const { singleView } = useStore((s) => s.ui.evidenceGaps);
  const isEditing = singleView.focusedFieldId === field.id;

  const [fieldContent, setFieldContent] = useState<
    Partial<EvidenceGapFieldContent>
  >(singleView.fields[field.id] ?? defaultContent);

  useEffect(() => {
    if (!isEditing) {
      setFieldContent(singleView.fields[field.id] ?? defaultContent);
    }
  }, [isEditing, singleView.fields]);

  const selectedOptions = fieldContent.jsonContent;

  const handleSelect = (region: Region, option) => () => {
    fieldContent.jsonContent[region] ??= {};
    fieldContent.jsonContent[region][option] = !fieldContent.jsonContent[
      region
    ][option];
    setFieldContent(fieldContent);
    singleView.updateField(field.id, fieldContent);
  };

  return (
    <div className={"flex-1 p-2 w-full overflow-hidden"}>
      <div
        className={
          "w-full h-full border border-slate-300 rounded-lg cursor-pointer p-2 gap-2 overflow-y-auto flex flex-col"
        }
      >
        {!isEditing && (
          <>
            <ul
              className={""}
              onClick={() => (singleView.focusedFieldId = field.id)}
            >
              <li className={"flex gap-2 py-1"}>
                <svg
                  className="w-6 h-6"
                  enable-background="new 0 0 512 512"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="256" cy="256" fill="#338af3" r="256" />
                  <g fill="#f0f0f0">
                    <path d="m255.26 166.957c-49.179 0-89.043 39.866-89.043 89.043s39.865 89.043 89.043 89.043c49.176 0 89.043-39.866 89.043-89.043s-39.868-89.043-89.043-89.043zm-22.261 89.043c0-12.294 9.966-22.261 22.261-22.261 12.294 0 22.261 9.967 22.261 22.261 0 12.295-9.967 22.261-22.261 22.261-12.295 0-22.261-9.966-22.261-22.261zm45.341-38.079c-3.455-2.099-7.221-3.729-11.209-4.83v-22.8c10.041 1.802 19.299 5.841 27.245 11.595zm-33.469-5.211c-4.177.999-8.123 2.586-11.749 4.669l-16.11-16.11c8.144-5.702 17.612-9.641 27.859-11.244zm-11.75 81.911c3.626 2.082 7.572 3.67 11.749 4.668v22.684c-10.248-1.601-19.714-5.542-27.858-11.244zm34.011 4.288c3.987-1.101 7.755-2.73 11.208-4.829l16.035 16.035c-7.946 5.753-17.203 9.793-27.244 11.595v-22.8h.001zm26.748-20.769c1.961-3.413 3.494-7.105 4.499-11.009h22.722c-1.672 9.962-5.546 19.171-11.11 27.117zm4.499-33.27c-1.005-3.905-2.538-7.594-4.499-11.008l16.11-16.11c5.564 7.947 9.439 17.155 11.11 27.117h-22.722v.001zm-97.234-27.985 16.035 16.035c-2.229 3.67-3.942 7.686-5.04 11.95h-22.722c1.733-10.326 5.832-19.844 11.727-27.985zm-11.727 50.245h22.722c1.097 4.264 2.81 8.28 5.04 11.95l-16.035 16.035c-5.895-8.141-9.994-17.658-11.727-27.985z" />
                    <path d="m399.955 256c0-39.58-15.898-75.441-41.648-101.565l-23.611 23.611c19.708 20.08 31.868 47.596 31.868 77.954 0 61.472-49.833 111.304-111.304 111.304-61.473 0-111.304-49.832-111.304-111.304 0-30.737 12.457-58.563 32.6-78.704l-23.611-23.611c-26.185 26.185-42.38 62.359-42.38 102.315 0 66.215 44.477 122.042 105.186 139.235-6.656 12.644-5.642 28.563 3.866 40.428 12.488-10.008 25.417-20.37 36.385-29.158 10.968 8.789 23.898 19.15 36.385 29.158 9.602-11.983 10.547-28.104 3.67-40.809 60.045-17.615 103.898-73.109 103.898-138.854z" />
                  </g>
                </svg>
                <h6 className={"font-bold mb-2"}>Global</h6>
              </li>
              {Object.keys(ownersByRegion[Region.GLOBAL]).map((option) => (
                <li key={option} className={"flex gap-2"}>
                  {selectedOptions[Region.GLOBAL]?.[option] ? (
                    <CheckBoxOutlined color={"primary"} />
                  ) : (
                    <CheckBoxOutlineBlank color={"primary"} />
                  )}
                  <p className={"text"}>{option}</p>
                </li>
              ))}
            </ul>
            <Divider />
            <ul
              className={""}
              onClick={() => (singleView.focusedFieldId = field.id)}
            >
              <li className={"flex gap-2 py-1"}>
                <svg
                  className="w-6 h-6"
                  enable-background="new 0 0 512 512"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="256" cy="256" fill="#f0f0f0" r="256" />
                  <g fill="#d80027">
                    <path d="m244.87 256h267.13c0-23.106-3.08-45.49-8.819-66.783h-258.311z" />
                    <path d="m244.87 122.435h229.556c-15.671-25.572-35.708-48.175-59.07-66.783h-170.486z" />
                    <path d="m256 512c60.249 0 115.626-20.824 159.356-55.652h-318.712c43.73 34.828 99.107 55.652 159.356 55.652z" />
                    <path d="m37.574 389.565h436.852c12.581-20.529 22.338-42.969 28.755-66.783h-494.362c6.417 23.814 16.174 46.254 28.755 66.783z" />
                  </g>
                  <path
                    d="m118.584 39.978h23.329l-21.7 15.765 8.289 25.509-21.699-15.765-21.699 15.765 7.16-22.037c-19.106 15.915-35.852 34.561-49.652 55.337h7.475l-13.813 10.035c-2.152 3.59-4.216 7.237-6.194 10.938l6.596 20.301-12.306-8.941c-3.059 6.481-5.857 13.108-8.372 19.873l7.267 22.368h26.822l-21.7 15.765 8.289 25.509-21.699-15.765-12.998 9.444c-1.301 10.458-1.979 21.11-1.979 31.921h256c0-141.384 0-158.052 0-256-50.572 0-97.715 14.67-137.416 39.978zm9.918 190.422-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765zm-8.289-100.083 8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822zm100.115 100.083-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765zm-8.289-100.083 8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822zm0-74.574 8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822z"
                    fill="#0052b4"
                  />
                </svg>
                <h6 className={"font-bold mb-2"}>USA</h6>
              </li>
              {Object.keys(ownersByRegion[Region.UNITED_STATES]).map(
                (option) => (
                  <li key={option} className={"flex gap-2"}>
                    {selectedOptions[Region.UNITED_STATES]?.[option] ? (
                      <CheckBoxOutlined color={"primary"} />
                    ) : (
                      <CheckBoxOutlineBlank color={"primary"} />
                    )}
                    <p className={"text"}>{option}</p>
                  </li>
                )
              )}
            </ul>
            <Divider />
            <ul
              className={""}
              onClick={() => (singleView.focusedFieldId = field.id)}
            >
              <li className={"flex gap-2 py-1"}>
                <svg
                  className="w-6 h-6"
                  enable-background="new 0 0 512 512"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="256" cy="256" fill="#f0f0f0" r="256" />
                  <circle cx="256" cy="256" fill="#d80027" r="111.304" />
                </svg>
                <h6 className={"font-bold mb-2"}>Japan</h6>
              </li>
              {Object.keys(ownersByRegion[Region.JAPAN]).map((option) => (
                <li key={option} className={"flex gap-2"}>
                  {selectedOptions[Region.JAPAN]?.[option] ? (
                    <CheckBoxOutlined color={"primary"} />
                  ) : (
                    <CheckBoxOutlineBlank color={"primary"} />
                  )}
                  <p className={"text"}>{option}</p>
                </li>
              ))}
            </ul>
          </>
        )}
        {isEditing && (
          <>
            <ul className={""}>
              <li className={"flex gap-2 p-2"}>
                <svg
                  className="w-6 h-6"
                  enable-background="new 0 0 512 512"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="256" cy="256" fill="#338af3" r="256" />
                  <g fill="#f0f0f0">
                    <path d="m255.26 166.957c-49.179 0-89.043 39.866-89.043 89.043s39.865 89.043 89.043 89.043c49.176 0 89.043-39.866 89.043-89.043s-39.868-89.043-89.043-89.043zm-22.261 89.043c0-12.294 9.966-22.261 22.261-22.261 12.294 0 22.261 9.967 22.261 22.261 0 12.295-9.967 22.261-22.261 22.261-12.295 0-22.261-9.966-22.261-22.261zm45.341-38.079c-3.455-2.099-7.221-3.729-11.209-4.83v-22.8c10.041 1.802 19.299 5.841 27.245 11.595zm-33.469-5.211c-4.177.999-8.123 2.586-11.749 4.669l-16.11-16.11c8.144-5.702 17.612-9.641 27.859-11.244zm-11.75 81.911c3.626 2.082 7.572 3.67 11.749 4.668v22.684c-10.248-1.601-19.714-5.542-27.858-11.244zm34.011 4.288c3.987-1.101 7.755-2.73 11.208-4.829l16.035 16.035c-7.946 5.753-17.203 9.793-27.244 11.595v-22.8h.001zm26.748-20.769c1.961-3.413 3.494-7.105 4.499-11.009h22.722c-1.672 9.962-5.546 19.171-11.11 27.117zm4.499-33.27c-1.005-3.905-2.538-7.594-4.499-11.008l16.11-16.11c5.564 7.947 9.439 17.155 11.11 27.117h-22.722v.001zm-97.234-27.985 16.035 16.035c-2.229 3.67-3.942 7.686-5.04 11.95h-22.722c1.733-10.326 5.832-19.844 11.727-27.985zm-11.727 50.245h22.722c1.097 4.264 2.81 8.28 5.04 11.95l-16.035 16.035c-5.895-8.141-9.994-17.658-11.727-27.985z" />
                    <path d="m399.955 256c0-39.58-15.898-75.441-41.648-101.565l-23.611 23.611c19.708 20.08 31.868 47.596 31.868 77.954 0 61.472-49.833 111.304-111.304 111.304-61.473 0-111.304-49.832-111.304-111.304 0-30.737 12.457-58.563 32.6-78.704l-23.611-23.611c-26.185 26.185-42.38 62.359-42.38 102.315 0 66.215 44.477 122.042 105.186 139.235-6.656 12.644-5.642 28.563 3.866 40.428 12.488-10.008 25.417-20.37 36.385-29.158 10.968 8.789 23.898 19.15 36.385 29.158 9.602-11.983 10.547-28.104 3.67-40.809 60.045-17.615 103.898-73.109 103.898-138.854z" />
                  </g>
                </svg>
                <h6 className={"font-bold mb-2"}>Global</h6>
              </li>
              {Object.keys(ownersByRegion[Region.GLOBAL]).map((option) => (
                <li
                  key={option}
                  className={
                    "flex gap-2 p-2 border border-slate-200 mb-2 hover:bg-slate-100 rounded-lg cursor-pointer"
                  }
                  onClick={handleSelect(Region.GLOBAL, option)}
                >
                  {selectedOptions[Region.GLOBAL]?.[option] ? (
                    <CheckBoxOutlined color={"primary"} />
                  ) : (
                    <CheckBoxOutlineBlank color={"primary"} />
                  )}
                  <p className={"text"}>{option}</p>
                </li>
              ))}
            </ul>
            <ul className={""}>
              <li className={"flex gap-2 p-2"}>
                <svg
                  className="w-6 h-6"
                  enable-background="new 0 0 512 512"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="256" cy="256" fill="#f0f0f0" r="256" />
                  <g fill="#d80027">
                    <path d="m244.87 256h267.13c0-23.106-3.08-45.49-8.819-66.783h-258.311z" />
                    <path d="m244.87 122.435h229.556c-15.671-25.572-35.708-48.175-59.07-66.783h-170.486z" />
                    <path d="m256 512c60.249 0 115.626-20.824 159.356-55.652h-318.712c43.73 34.828 99.107 55.652 159.356 55.652z" />
                    <path d="m37.574 389.565h436.852c12.581-20.529 22.338-42.969 28.755-66.783h-494.362c6.417 23.814 16.174 46.254 28.755 66.783z" />
                  </g>
                  <path
                    d="m118.584 39.978h23.329l-21.7 15.765 8.289 25.509-21.699-15.765-21.699 15.765 7.16-22.037c-19.106 15.915-35.852 34.561-49.652 55.337h7.475l-13.813 10.035c-2.152 3.59-4.216 7.237-6.194 10.938l6.596 20.301-12.306-8.941c-3.059 6.481-5.857 13.108-8.372 19.873l7.267 22.368h26.822l-21.7 15.765 8.289 25.509-21.699-15.765-12.998 9.444c-1.301 10.458-1.979 21.11-1.979 31.921h256c0-141.384 0-158.052 0-256-50.572 0-97.715 14.67-137.416 39.978zm9.918 190.422-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765zm-8.289-100.083 8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822zm100.115 100.083-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822l-21.7 15.765zm-8.289-100.083 8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822zm0-74.574 8.289 25.509-21.699-15.765-21.699 15.765 8.289-25.509-21.7-15.765h26.822l8.288-25.509 8.288 25.509h26.822z"
                    fill="#0052b4"
                  />
                </svg>
                <h6 className={"font-bold mb-2"}>USA</h6>
              </li>
              {Object.keys(ownersByRegion[Region.UNITED_STATES]).map(
                (option) => (
                  <li
                    key={option}
                    className={
                      "flex gap-2 p-2 border border-slate-200 mb-2 hover:bg-slate-100 rounded-lg cursor-pointer"
                    }
                    onClick={handleSelect(Region.UNITED_STATES, option)}
                  >
                    {selectedOptions[Region.UNITED_STATES]?.[option] ? (
                      <CheckBoxOutlined color={"primary"} />
                    ) : (
                      <CheckBoxOutlineBlank color={"primary"} />
                    )}
                    <p className={"text"}>{option}</p>
                  </li>
                )
              )}
            </ul>
            <ul className={""}>
              <li className={"flex gap-2 p-2"}>
                <svg
                  className="w-6 h-6"
                  enable-background="new 0 0 512 512"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="256" cy="256" fill="#f0f0f0" r="256" />
                  <circle cx="256" cy="256" fill="#d80027" r="111.304" />
                </svg>
                <h6 className={"font-bold mb-2"}>Japan</h6>
              </li>
              {Object.keys(ownersByRegion[Region.JAPAN]).map((option) => (
                <li
                  key={option}
                  className={
                    "flex gap-2 p-2 border border-slate-200 mb-2 hover:bg-slate-100 rounded-lg cursor-pointer"
                  }
                  onClick={handleSelect(Region.JAPAN, option)}
                >
                  {selectedOptions[Region.JAPAN]?.[option] ? (
                    <CheckBoxOutlined color={"primary"} />
                  ) : (
                    <CheckBoxOutlineBlank color={"primary"} />
                  )}
                  <p className={"text"}>{option}</p>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
});
